<template>
  <div>
    <!-- <Header/> -->
    <div class="common_header">
      <iframe
        id="geoPage"
        width="0"
        height="0"
        frameborder="0"
        style=""
        scrolling="no"
        src="https://apis.map.qq.com/tools/geolocation?key=FQOBZ-MWGC6-3HNSW-MFLZ5-HBCEO-3QB37&referer=myapp"
      >
      </iframe>
      <div class="header_top">
        <div class="top_box flex-between">
          <div style="margin-left: 10px">
            <span>欢迎来到禹佳商城</span>
            <span style="padding: 0 6px">|</span>
            <span class="iconfont icon-dizhi"></span>
            <span>{{ city }}</span>
          </div>
          <div class="flex-start">
            <div class="top_inforbox">
              <el-dropdown>
                <div>
                  <span class="iconfont icon-yonghu"></span>
                  <span class="user_name">{{ userName }}</span>
                  <span class="iconfont icon-zhankai"></span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="account()"
                    >个人中心</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="goOut()"
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-if="authorityJudgment([1, 2, 3, 4, 5])" class="top_inforbox">
              <span class="iconfont icon-dingdan1"></span>
              <span class="user_infor" @click="order()">我的订单</span>
            </div>
            <div class="top_inforbox">
              <span class="iconfont icon-kehufuwukefu"></span>
              <span class="user_infor" @click="openKeFu()">客服</span>
            </div>
            <div class="top_inforbox" style="padding-right: 0">
              <span class="iconfont icon-gongzuotai"></span>
              <span class="user_infor" @click="work()">工作台</span>
            </div>
            <div v-if="authorityJudgment([2, 3, 5, 6])" class="top_inforbox">
                <span class="iconfont icon-xiaoxi"></span>
                <span class="user_infor" @click="onNews()">消息 {{$store.state.countMsg.unRead ? `(${$store.state.countMsg.unRead})` : ''}}</span>
            </div>
            <!-- <div class="top_inforbox">
                <span class="iconfont icon-shoucangjia"></span>
                <span class="user_infor">收藏夹</span>
              </div>
              <div class="top_inforbox">
                <span class="iconfont icon-bangzhu"></span>
                <span class="user_infor">帮助</span>
              </div>
              <div class="top_inforbox" style="padding-right:0;">
                <span class="iconfont icon-xiaoxi"></span>
                <span class="user_infor">消息（2）</span>
              </div> -->
          </div>
        </div>
      </div>
      <div class="search_header_middle flex-between">
        <div class="top_box flex-between">
          <div class="flex-start">
            <img
              class="logo_img"
              src="@/assets/image/logo_1.png"
              alt=""
              @click="gotoindex()"
            />
          </div>
          <div class="flex-start">
            <div class="search_box flex-start">
              <div class="input_box">
                <el-input
                  type="text"
                  placeholder="请输入搜索商品名称"
                  class="search_input"
                  v-model="inputName"
                  @keyup.enter="searchGoods"
                ></el-input>
                <div class="iconfont icon-sousuo1"></div>
              </div>

              <button
                class="search_btn"
                @click="searchGoods()"
              >
                搜索
              </button>
            </div>
            <div class="shopping_cart">
              <div style="position: relative">
                <span class="iconfont icon-gouwuche"></span>
                <span style="margin-left: 15px" @click="shoppingCart()"
                  >购物车</span
                >
                <div class="carnumber">{{ carnumber }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="header_all flex-start">
          <div class="allGoods">全部商品</div>
          <div class="tips" v-show="$store.state.headerTips">{{$store.state.headerTips}}</div>
          <!-- <div class="tips">量大价优，具体联系禹佳采购 ，有起订量、指定地区或专供的商品请勿直接下单，先与采购联系！！</div> -->
        </div>
        <div class="header_xian"></div>
      </div>
    </div>
    <el-row type="flex" class="searchGoods">
      <el-col :span="3" style="width: 15.3%"></el-col>
      <el-col :span="17">
        <div class="search-goods">
          <div v-if="show === 1">
            <div class="search_top" v-show="input == 1">
              全部结果：{{ inputnameed }}
            </div>
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              v-show="input == 2"
            >
              <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">{{ item }}</el-breadcrumb-item>
              <!-- <el-breadcrumb-item v-show="smallInput === 1">{{
                goodsName
              }}</el-breadcrumb-item>
              <el-breadcrumb-item v-show="nameInput === 1">{{
                samllname
              }}</el-breadcrumb-item> -->
            </el-breadcrumb>
            <div class="search-condition" v-if="brandName.length == 0">
              <div
                :class="
                  mychar === false ? 'item-brand' : ' mycharbrand item-brand'
                "
              >
                <div class="name">品牌</div>
                <div class="item-cell">
                  <el-checkbox-group
                    v-if="isMultiple"
                    v-model="checkList"
                    style="display: flex; flex-wrap: wrap"
                    @change="checkboxGroupChange"
                  >
                    <div
                      class="attr-name flex-start"
                      v-for="(item, index) in brand"
                      :key="index"
                    >
                      <el-checkbox :label="item"></el-checkbox>
                    </div>
                  </el-checkbox-group>
                  <div
                    v-else
                    class="attr-name flex-start"
                    v-for="(item, index) in brand"
                    :key="index"
                  >
                    <span
                      @click="brandSearch(item)"
                      style="cursor: pointer;"
                      >{{ item }}</span
                    >
                  </div>
                </div>
                <div class="item-more flex-start" v-if="!isMultiple">
                  <div class="more_box" @click="unfold()">
                    {{ mychar ? "更多" : "收起"
                    }}<span class="iconfont icon-jiahao"></span>
                  </div>
                  <div class="more_box" @click="choice()">
                    多选<span class="iconfont icon-jiahao"></span>
                  </div>
                </div>
                <div class="item-more flex-start" v-else>
                  <div class="more_box" @click="multipleConfirm()">
                    确定
                    <span class="iconfont icon-jiahao"></span>
                  </div>
                  <div class="more_box" @click="multipleCancel()">
                    取消<span class="iconfont icon-jiahao"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="search-condition" v-else>
              <div class="item-brand">
                <div class="name">已选品牌</div>
                <div class="item-cell">
                  <div
                    class="attr-name flex-start"
                    v-for="(item, index) in brandTemp"
                    :key="index"
                  >
                    <span @click="brandSearch(item)" style="cursor: pointer;">{{
                      item
                    }}</span>
                  </div>
                </div>
                <div class="item-more flex-start">
                  <div class="more_box" @click="revoke()">
                    撤销
                    <span class="iconfont icon-jiahao"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="search-wraper">
              <div class="flex-between">
                <div class="flex-start">
                  <div class="flex-start">
                    <div
                      :class="color === 1 ? 'sort-cells' : 'sort-cell'"
                      @click="synthesize()"
                    >
                      综合
                      <span
                        v-show="allboult == 0"
                        class="iconfont icon-xiajiang"
                      ></span>
                      <span
                        v-show="allboult == 1"
                        class="iconfont icon-jiantou_xiangshang"
                      ></span>
                    </div>
                    <div
                      :class="color === 2 ? 'sort-cells' : 'sort-cell'"
                      @click="sales(salesboult)"
                    >
                      销量
                      <span
                        v-show="salesboult == 0"
                        class="iconfont icon-xiajiang"
                      ></span>
                      <!-- <span
                        v-show="salesboult == 1"
                        class="iconfont icon-jiantou_xiangshang"
                      ></span> -->
                    </div>
                    <div
                      :class="color === 3 ? 'sort-cells' : 'sort-cell'"
                      @click="price(priceboult)"
                    >
                      价格
                      <span
                        v-show="priceboult == 0"
                        class="iconfont icon-xiajiang"
                      ></span>
                      <span
                        v-show="priceboult == 1"
                        class="iconfont icon-jiantou_xiangshang"
                      ></span>
                    </div>
                  </div>
                  <div class="min-prict flex-start">
                    <el-input v-model="minprict"></el-input>
                    <div>-</div>
                    <el-input v-model="bigprict"></el-input>
                    <div class="minBtn" @click="minBtn()">
                      确定
                    </div>
                  </div>

                  <div></div>
                </div>
                <div>
                  <div>共{{ count }}件商品</div>
                  <div></div>
                </div>
              </div>
            </div>
            <div class="goods_box">
              <div
                class="goods_box_cent"
                v-for="(item, index) in goodsBox"
                :key="index"
              >
                <div class="goods_box_item" @click="goodsDetail(item.id)">
                  <div class="img-wrapper flex-center">
                    <img :src="item.homePicture" alt="" class="img" />
                  </div>
                  <el-tooltip :disabled="calcLength(item)" :content="item.externalId ? item.externalGoodsName : item.goodName" effect="dark" placement="top">
                    <div class="item_title more-ell">
                        <span class="title_name" v-show="item.sourceType == 0"
                        >自营</span
                        >
                        <span class="title_name" v-show="item.sourceType == 1"
                        >京东</span
                        >
                        <span class="title_name" v-show="item.sourceType == 2"
                        >震坤行</span
                        >
                        <span class="title_name" v-show="item.sourceType == 3"
                        >怡亚通</span
                        >
                        {{ item.externalId ? item.externalGoodsName : item.goodName }}
                    </div>
                  </el-tooltip>
                  <div class="item_price">￥{{ item.externalId ? item.externalMinPrice : item.minPrice }}</div>
                </div>
              </div>
            </div>
            <div class="pagination">
                <!-- <el-col :span="12">
                      <div class="selgoodscouts">共{{total}}个商品，已选择{{this.checkBoxData.length}}个商品<span v-show="this.checkBoxData.length >19">(最多选择20个商品，请删除后再添加其余商品)</span></div>
                    </el-col> -->
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="20"
                layout="prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
              
            </div>
          </div>

          <div v-else>
            <div class="no-goods flex-center">
              <img src="@/assets/image/03.png" alt="" />
              <span
                >抱歉，没有找到“{{
                  inputnameed
                }}”的搜索结果，为您推荐以下热门的商品</span
              >
            </div>
            <div>
              <div class="goods-guesslike">猜你喜欢</div>
              <div class="goods_box">
                <div
                  class="goods_box_cent"
                  v-for="(item, index) in goodsBox"
                  :key="index"
                >
                  <div class="goods_box_item" @click="goodsDetail(item.id)">
                    <div class="img-wrapper flex-center">
                      <img :src="item.homePicture" alt="" class="img" />
                    </div>
                    <div class="item_title">
                      <span class="title_name" v-show="item.sourceType == 0"
                        >自营</span
                      >
                      <span class="title_name" v-show="item.sourceType == 1"
                        >京东</span
                      >
                      <span class="title_name" v-show="item.sourceType == 2"
                        >震坤行</span
                      >
                      {{ item.externalId ? item.externalGoodsName : item.goodName }}
                    </div>
                    <div class="item_price">￥{{ item.externalId ? item.externalMinPrice : item.minPrice }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="3" style="width: 15.5%"></el-col>
    </el-row>
    <Footer />
    <FloatBox ref="floatBox"></FloatBox>
  </div>
</template>

<script>
// import Header from '@/components/Layout/header.vue'
import Footer from "@/components/Layout/footer.vue";
// 提示：floatBox 跟 Header 组件不能一起用
import FloatBox from "@/components/Layout/floatBox.vue";
import { clearLoginInfo } from "@/utils";
import { Inquire, carNumber, Classify, userInfor } from "@/api/api";
import { authorityJudgment } from "@/utils/util"
export default {
  components: { Footer, FloatBox },
  data() {
    return {
      authorityJudgment,
      radio: "",
      show: 1,
      breadcrumbList: [],
      inputnameed: "",
      inputnames: "",
      brand: [],
      goodsBox: [],
      count: "",
      color: 1,
      input: 1,
      smallInput: 0,
      goodsName: "",
      nameInput: 0,
      samllname: "",
      total: 0,
      currentPage: 1,
      id: [],
      pageIndex: 1,
      pageSize: 20,
      orderBy: "_score",
      minprict: "",
      bigprict: "",
      mychar: false,
      mycharText: "更多",
      showId: true,
      inputName: "",
      city: "",
      userName: "",
      isMultiple: false,
      checkList: [],
      brandTemp: [],
      brandName: [],
      carnumber: 0,
      allboult: 0,
      salesboult: 0,
      priceboult: 0,
      classify: [],
      classifyName: [],
      desc: 1,
    };
  },
  created() {
    console.log(this.$route.query,this.$store.state.common);
    if(this.$route.query.level != undefined){
      this.input = 2;
      Classify().then(data => {
        if (data && data.code === "00000") {
          this.classify = data.data;
          this.id = []
          if(this.$route.query.level == 1){
            let index = this.classify.findIndex(item => item.id == this.$route.query.id)
            // 面包屑
            this.breadcrumbList = [this.classify[index].name]

            this.classify[index].goodsTypeVOList.forEach(item => {
              item.goodsTypeVOList.forEach(items => {
                this.id.push(items.id)
              })
            })
          } else if(this.$route.query.level == 2){
            let index = this.classify.findIndex(item => item.id == this.$route.query.id)
            let i = this.classify[index].goodsTypeVOList.findIndex(item => item.id == this.$route.query.id2)
            // 面包屑
            this.breadcrumbList = [this.classify[index].name, this.classify[index].goodsTypeVOList[i].name]
            
            this.classify[index].goodsTypeVOList[i].goodsTypeVOList.forEach(item => {
                this.id.push(item.id)
            })
          } else if(this.$route.query.level == 3){
            let index = this.classify.findIndex(item => item.id == this.$route.query.id)
            let i = this.classify[index].goodsTypeVOList.findIndex(item => item.id == this.$route.query.id2)
            let j = this.classify[index].goodsTypeVOList[i].goodsTypeVOList.findIndex(item => item.id == this.$route.query.id3)
            // 面包屑
            this.breadcrumbList = [
              this.classify[index].name,
              this.classify[index].goodsTypeVOList[i].name,
              this.classify[index].goodsTypeVOList[i].goodsTypeVOList[j].name
            ]
            this.id.push(this.$route.query.id3)
          }
          this.searchGoods()
        }
      });
    } else if (this.$route.query.input != undefined) {
      this.input = 1;
      this.inputName = this.$route.query.input;
      this.inputnameed = this.$route.query.input;
      this.searchGoods()
    }
    
    this.Tmap();
    
    userInfor().then(data => {
      this.userName=data.data.userName
      this.$store.commit("setAcc", data.data.userPhone);
      this.$store.commit("setId", { userId: data.data.userId, externalId: data.data.externalId });
    });
    carNumber().then(data => {
      if (data && data.code == "00000") {
        this.carnumber = data.data;
      }
    });
    let that = this;
    document.onkeypress = function(e) {
      let codeKey = document.all ? event.keyCode : e.which;
      if (codeKey == 13) {
        that.searchGoods();
        return false;
      }
    };
  },

  methods: {
    calcLength(item) {
        let width = document.querySelector('.goods_box').clientWidth * 19 / 100 - 44
        let textLength = item.externalId ? item.externalGoodsName.length : item.goodName.length
        let length = parseInt(14 * (textLength + 3))
        // console.log(length,width);
        if(length >= (width * 2)){
            return false
        }
        return true
    },
    //品牌搜索
    brandSearch(brandName) {
      this.brandName = [brandName];
      this.brandTemp = [brandName];
      // this.brand = [brandName]
      this.searchGoods();
    },
    //获取地址
    Tmap() {
      let that = this;
      window.addEventListener(
        "message",
        function(event) {
          if (event.data != null) {
            //这个会获取到四条数据，其中有一条数据为null，然后你如果是用变量去获取，会报错
            if (event.data.module == "geolocation") {
              //去掉一条null后还有一条杂数据，里面没啥东西，然后你要是用变量获取位置还是会报错，所有过滤掉非该类型的数据
              that.city = event.data.city;
            }
          }
        },
        false
      );
    },
    
    //商品详情
    goodsDetail(id) {
      if(this.$routerWin){
        const { href } = this.$router.resolve({ name: "goodsDetail", query: { id: id } });
        window.open(href,'_blank');
      } else {
        this.$router.push({ name: "goodsDetail", query: { id: id } });
      }
    },
    //分页搜索
    getItem(val, id, inputName) {
      let data = {
        myPage: {
            pageNum: val,
            pageSize: this.pageSize
        },
        goodsTypeIds: id,
        name: inputName,
        orderBy: this.orderBy,
        minPrice: parseInt(this.minprict),
        maxPrice: parseInt(this.bigprict),
        brand: [],
        desc: this.desc
      };
      Inquire(data).then(data => {
        if (data && data.code === "00000") {
            // this.goodsBox = data.data.goodsList;
            this.searchSuccessFn(data)
        }
      });
    },
    handleSizeChange(val) {
      this.getItem(val, this.id, this.inputName);
    },
    handleCurrentChange(val) {
      this.getItem(val, this.id, this.inputName);
    },
    //品牌收起展开
    unfold() {
      // console.log(this.mycharText);
      this.mychar = !this.mychar;
    },
    //更多
    choice() {
      this.isMultiple = true;
      this.mychar = true;
    },
    //确认多选
    multipleConfirm() {
      this.isMultiple = false;
      this.mychar = false;
      this.brandName = this.brandTemp;
      this.searchGoods();
    },
    //取消多选
    multipleCancel() {
      this.isMultiple = false;
      this.mychar = false;
      this.checkList = [];
    },
    // 选中任一checkbox时，由checkbox-group触发
    checkboxGroupChange(e) {
      this.brandTemp = e;
    },
    //全部撤销
    revoke() {
      this.isMultiple = false;
      this.checkList = [];
      this.brandName = [];
      this.brandTemp = [];
      this.searchGoods();
    },
    //综合
    synthesize() {
      this.orderBy = "_score";
      this.desc = 1
      let data = {
        myPage: {
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        },
        name: this.inputName,
        goodsTypeIds: this.id,
        orderBy: "_score",
        minPrice: parseInt(this.minprict),
        maxPrice: parseInt(this.bigprict),
        brand: [],
        desc: this.desc 
      };
      Inquire(data).then(data => {
        if (data && data.code === "00000") {
          this.color = 1;
        //   this.goodsBox = data.data.goodsList;
        //   this.count = data.data.total;
        //   this.total = parseInt(data.data.total);
          this.searchSuccessFn(data)
        }
      });
    },
    //销量
    sales(salesboult) {
      this.orderBy = "sales";
      if (salesboult == 0) {
        this.desc = 1
        let data = {
          myPage: {
            pageNum: this.pageIndex,
            pageSize: this.pageSize
          },
          name: this.inputName,
          goodsTypeIds: this.id,
          orderBy: "sales",
          minPrice: parseInt(this.minprict),
          maxPrice: parseInt(this.bigprict),
          brand: [],
          desc: this.desc,
        };
        // console.log(data);
        Inquire(data).then(data => {
          if (data && data.code === "00000") {
            // console.log(data);
            this.color = 2;
            // this.goodsBox = data.data.goodsList;
            // this.count = data.data.total;
            // this.total = parseInt(data.data.total);
            this.searchSuccessFn(data)
          }
        });
      } 
     
    },
    //价格
    price(priceboult) {
      this.orderBy = "minPrice";
      if (priceboult == 0) {
        this.desc = 0
        let data = {
          myPage: {
            pageNum: this.pageIndex,
            pageSize: this.pageSize
          },
          name: this.inputName,
          goodsTypeIds: this.id,
          orderBy: "minPrice",
          minPrice: parseInt(this.minprict),
          maxPrice: parseInt(this.bigprict),
          brand: this.brandName,
          desc: this.desc,
          
        };
        Inquire(data).then(data => {
          if (data && data.code === "00000") {
            this.color = 3;
            this.priceboult = 1;
            // console.log(data.data);
            // this.goodsBox = data.data.goodsList;
            // this.count = data.data.total;
            // this.total = parseInt(data.data.total);
            this.searchSuccessFn(data)
          }
        });
      } else {
        this.desc = 1
        let data = {
          myPage: {
            pageNum: this.pageIndex,
            pageSize: this.pageSize
          },
          name: this.inputName,
          goodsTypeIds: this.id,
          orderBy: "minPrice",
          minPrice: parseInt(this.minprict),
          maxPrice: parseInt(this.bigprict),
          brand: this.brandName,
          desc: this.desc
        };
        Inquire(data).then(data => {
          if (data && data.code === "00000") {
            this.color = 3;
            this.priceboult = 0;
            // console.log(data.data);
            // this.goodsBox = data.data.goodsList;
            // this.count = data.data.total;
            // this.total = parseInt(data.data.total);
            this.searchSuccessFn(data)
          }
        });
      }
    },
    //价格区间
    minBtn() {
      let data = {
        myPage: {
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        },
        name: this.inputName,
        goodsTypeIds: this.id,
        orderBy: this.orderBy,
        minPrice: parseInt(this.minprict),
        maxPrice: parseInt(this.bigprict),
        brand: [],
        desc: this.desc
      };
      Inquire(data).then(data => {
        if (data && data.code === "00000") {
          // console.log(data.data);
        //   this.goodsBox = data.data.goodsList;
        //   this.count = data.data.total;
        //   this.total = parseInt(data.data.total);
          this.searchSuccessFn(data)
        }
      });
    },
    //搜索
    searchGoods() {
      this.minprict = "";
      this.bigprict = "";
      this.orderBy = "_score";
      this.total = 0
      this.desc = 1
      // console.log(this.id,'----ids----');
      let data = {
        myPage: {
          pageNum: this.pageIndex,
          pageSize: this.pageSize
        },
        name: this.inputName,
        goodsTypeIds: this.id,
        orderBy: this.orderBy,
        brand: this.brandName,
        desc: this.desc
      };
      Inquire(data).then(data => {
        if (data && data.code === "00000") {
          this.searchSuccessFn(data, true)
        }
      });
    },
    searchSuccessFn(data, search = false) {
        this.show = data.data.exist ? 1 : 2;
        this.goodsBox = data.data.goodsList;
        this.count = data.data.total;
        this.total = parseInt(data.data.total);
        if(search){
            this.brand = data.data.brand;
        }
        this.inputnameed = this.inputName || this.breadcrumbList[0];
    },
    shoppingCart() {
      this.$router.push({ name: "ShoppingCart" });
    },
    account() {
      this.$router.push({ name: "AccountSettings" });
    },
    goOut() {
      clearLoginInfo();
    },
    gotoindex() {
      this.$router.push({ name: "Index" });
    },
    order() {
      this.$router.push({ name: "MyOrder" });
    },
    work() {
      this.$router.push({ name: "Personalwork" });
    },
    openKeFu() {
      this.$refs.floatBox.kefuDialog()
    },
    
  }
};
</script>

<style lang="scss">
.common_header {
  width: 100%;
}
#geoPage {
  display: none;
}
.top_box {
  width: 70%;
  margin: 0 auto;
}
.header_top {
  height: 42px;
  line-height: 42px;
  background: #c9c9c9;
  font-size: 14px;

  .top_inforbox {
    margin-right: 25px;
  }
  .user_name {
    padding: 0 5px;
  }
  .el-dropdown-link {
    cursor: pointer;
  }
  .el-dropdown-menu {
    top: 55px !important;
  }
  .user_infor {
    padding-left: 3px;
  }
}
.search_header_middle {
  height: 80px;
  margin: 20px 0;
  .logo_img {
    width: 200px;
    height: 45px;
  }
  .close_text {
    margin-left: 40px;
    font-size: 24px;
  }
  .search_box {
    // margin-right: 35px;
    .input_box {
      position: relative;
      .icon-sousuo1 {
        position: absolute;
        top: 50%;
        right: 9px;
        font-size: 33px;
        transform: translateY(-50%);
      }
    }
    .el-input__inner {
      width: 346px;
      height: 49px;
      line-height: 49px;
      padding-left: 10px;
      border: 1px solid #797979;
      border-radius: 0;
    }
    .search_btn {
      width: 88px;
      height: 49px;
      line-height: 48px;
      text-align: center;
      background: #cd9f49;
      color: #fff;
      border: 1px solid #797979;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        font-size: 15px;
      }
    }
  }
  .shopping_cart {
    width: 163px;
    height: 48px;
    line-height: 48px;
    margin-left: 35px;
    border: 1px solid #797979;
    text-align: center;
    margin-right: 10px;
    .carnumber {
      position: absolute;
      top: 20%;
      right: 55%;
      height: 16px;
      line-height: 16px;
      font-size: 10px;
      transform: none;
      background: #f2270c;
      min-width: 20px;
      color: #fff;
      border-radius: 8px;
    }
    &:hover {
      cursor: pointer;
      color: #cd9f49;
      border-color: #cd9f49;
    }
  }
}
.header_all {
  width: 70%;
  margin: 0 auto;
  .allGoods {
    width: 193px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #cd9f49;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
  }
  .tips {
    margin: -12px 0 0 10px;
    color: red;
    font-size: 24px;
    font-weight: bold;
    max-width: 610px;
  }
}
.header_xian {
  height: 5px;
  background: #cd9f49;
}
.searchGoods {
  background: #f7f7f7;
}
.search-goods {
  .search_top {
    padding: 16px 0;
  }
  .el-breadcrumb {
    font-size: 18px;
    padding: 15px 0;
    .el-breadcrumb__inner {
      color: #cd9f49;
    }
    .el-breadcrumb__separator {
      color: #cd9f49;
    }
  }
  .search-condition {
    font-size: 12px;
    color: #666;
    box-sizing: border-box;
    margin: 0 auto 16px;
    transform: translateZ(0);
    border: 1px solid #ddd;
    border-bottom: none;
    background: #fff;
    .mycharbrand {
      height: 100% !important;
      overflow: auto !important;
    }
    .item-brand {
      display: flex;
      flex-grow: 1;
      padding: 12px 0;
      border-bottom: 1px solid #ececec;
      height: 80px;
      overflow: hidden;
      align-items: baseline;
      overflow: hidden;
      .item-more {
        width: 18%;
        flex-shrink: 0;
      }
      .name {
        width: 9%;
        line-height: 24px;
        height: 24px;
        padding-left: 20px;
        flex-shrink: 0;
      }
      .item-cell {
        width: 83%;
        display: flex;
        flex-flow: wrap;
        // overflow: hidden;
      }
    }
    .item-cell {
      .attr-name {
        width: 155px;
        line-height: 30px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 8px;
        .el_Input {
          display: block;
          margin-right: 10px;
        }
      }
    }
    .more_box {
      box-sizing: border-box;
      display: inline-block;
      height: 24px;
      padding: 0 10px;
      line-height: 22px;
      text-align: center;
      border: 1px solid #ddd;
      margin-right: 10px;
      width: 70px;

      .iconfont.icon-jiahao {
        height: 1em;
        vertical-align: -0.08em;
      }
      &:hover {
        color: #cd9f49;
        cursor: pointer;
      }
    }
  }
  .search-wraper {
    height: 54px;
    line-height: 54px;
    align-items: center;
    padding: 0 20px;
    border: 1px solid #ddd;
    font-size: 12px;
    color: #666;
    background: #fff;
    .sort-cell:first-child {
      border-right: none;
    }
    .sort-cell {
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      width: 58px;
      height: 24px;
      justify-content: center;
      &:hover {
        color: #cd9f49;
        cursor: pointer;
      }
    }
    .sort-cells {
      background: red;
      color: #fff;
      display: flex;
      align-items: center;
      border: 1px solid #ddd;
      width: 58px;
      height: 24px;
      justify-content: center;
    }
    .min-prict {
      margin-left: 15px;
      .el-input {
        width: 60px;
        margin: 0 10px;
        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
      .minBtn {
        border: 1px solid #999;
        border-radius: 4px;
        padding: 0 6px;
        height: 28px;
        line-height: 28px;
        &:hover {
          color: #cd9f49;
          cursor: pointer;
        }
      }
    }
  }
  .goods_box {
    .goods_box_cent:nth-child(5n) {
      margin-right: 0;
    }
    .goods_box_cent {
      display: inline-block;
      width: 19.39%;
      margin-right: 0.75%;
      margin-bottom: 10px;
      background: #fff;
      .goods_box_item {
        padding: 22px;
        cursor: pointer;
        .img-wrapper {
          height: 170px;
          overflow: hidden;
        }
        .img {
          width: 100%;
          //   height: 170px;
        }
        .item_title {
          margin: 25px 0;
          font-size: 14px;
          color: #666;
          line-height: 20px;
          min-height: 40px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          .title_name {
            color: #fff;
            background: #cd9f49;
            padding: 0 5px;
          }
        }
        .item_price {
          color: #f2270c;
          text-align: left;
          font-weight: 700;
          font-size: 20px;
        }
      }
      .goods_box_item:hover {
        box-shadow: 0px 0px 5px #cd9f49;
      }
    }
  }
  .pagination {
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #cd9f49;
  }
  .no-goods {
    margin: 20px 0;
    span {
      margin-left: 10px;
    }
  }
  .goods-guesslike {
    height: 33px;
    line-height: 33px;
    background: #f2f2f2;
    padding-left: 12px;
  }
}
</style>
